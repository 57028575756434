<script setup lang="ts">
import useScrollToDiv from '~/composables/useScrollToDiv';

import {ref} from "vue";

const { getItems } = useDirectusItems();
const {title,logo,  } = useHMGlobalStore();


interface Nav {
  id:number
  name: string;
  path: string
}

const navs = ref<Nav[]>([]);

// Fetch nav  data
const fetchNavData = async () => {
  try {
    const data = await getItems<Nav>({
      collection: 'nav',
      fields: ['id','name', 'path'],
    });
    navs.value = data;

  } catch (e) {
    console.error("Failed to fetch data:", e);
  }
};

onMounted(fetchNavData);





const route = useRoute();
const open = ref(false);

// const menuitems = computed(() => [
//   {
//     title: 'főoldal',
//     path: "/template1/",
//   },
//   {
//     title: "about",
//     path: "/template1/#about",
//   },
//   {
//     title: "portfolio",
//     path: "/template1/#portfolio",
//   },
//   {
//     title: "clients",
//     path: "/template1/#clients",
//   },
//   {
//     title: "work",
//     path: "/template1/#work",
//   },
//
//   {
//     title: "Blog",
//     path: "/template1/#blog",
//   },
//   {
//     title: "Contact",
//     path: "/template1/contact",
//   },
// ]);

// Watch for route changes to handle hash navigation within the same page
// watch(route, (to, from) => {
//   if (to.hash) {
//     nextTick(() => {
//       const element = document.getElementById(to.hash.substring(1));
//       if (element) {
//         element.scrollIntoView({ behavior: 'smooth' });
//       }
//     });
//   }
// });
useScrollToDiv(navs)
</script>


<template>

  <div class="absolute inset-x-0 top-0 z-50 mt-50">
    <div class="text-center py-3 w-full bg-white">
      <NuxtLink class="text-xl" to="/"><Icon name="i-line-md:chevron-small-triple-left" size="20" /> vissza</NuxtLink>

    </div>
    <header class="flex flex-col lg:flex-row justify-between items-center my-5 container mx-auto">
      <div class="flex w-full lg:w-auto items-center justify-between">
        <NuxtLink to="/template1/" class="text-lg ml-3 lg:ml-0">
          <NuxtImg provider="directus"
                   :src="logo"
                   sizes="150px"
                   :alt="title" class="max-w-[220px]" />

        </NuxtLink>
        <div class="block lg:hidden mr-3">
          <button @click="open = !open" class="text-white">
            <Icon v-if="!open" name="heroicons-outline:menu-alt-3" size="40" />
            <Icon v-else name="heroicons-outline:x" size="40" />
          </button>
        </div>
      </div>
      <nav :class="{ 'block': open, 'hidden': !open }" class="w-full lg:w-auto mt-2 lg:flex lg:mt-0">
        <ul class="flex flex-col ml-5 lg:ml-0 lg:flex-row lg:gap-3">
          <li v-for="item in navs" :key="item.id">
            <NuxtLink :to="item.path" class="flex lg:px-3 py-2 text-white hover:text-gray-400">
              {{ item.name }}
            </NuxtLink>
          </li>
        </ul>
      </nav>
    </header>
  </div>
</template>
